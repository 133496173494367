import games from './Data/GamesCategories.json'
import {useState, useEffect} from 'react';
import '../App.css';
import { LeaderBoard } from './Leaderboard';

export function CategoryMenu(props){

    const [activeCategory, setActiveCategory] = useState('');

    var currentGame = games.filter((d) => {return d.game === props.game})
    const d = props.data.data;

    var fileCat = activeCategory

    if(activeCategory.length == 0){
        if(currentGame[0].srcGame === 'mmx'){
            fileCat = "Any%"
        }
        else if(currentGame[0].srcGame === 'mmx2'){
            fileCat = "Any% (SNES)"
        }
        else if(currentGame[0].srcGame === 'mmx3'){
            fileCat = "Any%"
        }
        else if (currentGame[0].srcGame === 'mmx4'){
            fileCat = "100% (Zero)"
        }
        else if(currentGame[0].srcGame === 'mmx5'){
            fileCat = "All Stages"
        }
        else if(currentGame[0].srcGame === 'mmx6'){
            fileCat = "All Stages"
        }
        else if(currentGame[0].srcGame === 'mmx7'){
            fileCat = "Any% "
        }
        else if(currentGame[0].srcGame === 'mmx8'){
            fileCat = "Any% (PC)"
        }
    }

    var categories = currentGame[0].Categories
    var filePath = String(currentGame[0].srcGame) + "-" + String(fileCat)
       
    var filteredData = d.filter((element) => {
        
        return(String(element.path) === String(filePath).replace('/', ' '))
    })

    var outData = filteredData[0].data

    return(
        <div>
            <div className="category-buttons-parent">
            {categories.map((category, index) => (
                <div key={index}>
                    <div className='category-buttons-child' onClick={() => {setActiveCategory(category)}}>
                        {getActiveTab(category, activeCategory, fileCat)}
                    </div>
                </div>
            ))}
            </div>
            {<LeaderBoard data={outData}/>}
        </div>
    ) 
}

function getActiveTab(category, location, fileCat){
    return ((location === category || category === fileCat)
            ? 
            <button style={{opacity: 1, background: `linear-gradient(-70deg, rgba(47, 185, 201, 0.3) 50%, rgba(0, 35, 96, 0.3) )`}} >
                {category}
            </button>
            : 
            <button style={{opacity: 1}} >
                {category}
            </button>
            )
  }