import './App.css';
import './tools/Leaderboard.css'
import { GameMenu } from './tools/GameMenu';

//import { TabHome } from './StyledDivs';
import {useState, useEffect} from 'react';
import * as d3 from 'd3';

export default function App() {
  const [data, setData] = useState(null);

  console.log(d3.json('/Data/Leaderboards/AllData.json'))

  if(data == null){
    d3.json('/Data/Leaderboards/AllData.json').then((d) => {
        setData(d);
    });
  }

  if(data){
    console.log(data)
    return (
      <>
        <header>
          <img src='/bannerv3.png' style={{width: '96.5vw', maxWidth: '1920px'}}></img>
          
        </header>
        <div className= 'tagline'>
          <div>
            <div>
              Your source for the most complete and accurate Mega Man X speedrun leaderboards.
            </div>
            <div>
              We are both diverse AND inclusive. ALL runners are welcome!
            </div>
          </div>
        </div>
        <div className= 'lb-footer'>
          <div>
          Don't see your time? Send us an <a href="mailto:megamanleaderboards@fastmail.com" target='_blank'> email</a> with your Player name, Game, Category with version (where required), and VOD, and we will add it in! 
            </div>
        </div>
        <div className='Leaderboard'>
          <GameMenu data={data}></GameMenu>
        </div>
      </>
    );
  }else{
    return(
      <>loading...</>
    )
  }
}
