// Leaderboard Styling/Html build. Gets called in App.js
export function LeaderBoard(data) {

    var data = data.data
    if(data){
        data = data.filter(element => {
            return element.verified >= 0
        })
    
        // Tool to retrieve data. Currently stored locally. See DataMerge.js
        var runs = data;
        
        var times = {}
        var currentPlace = 1
        var pattern = {0: 'Entry', 1: 'EntryOther'}
    
        runs.forEach(element => {
            if(times[element.time] != null){
                currentPlace += 1
            }else{
                times[element.time] = currentPlace
                currentPlace += 1
            }
        });
    
        return (
            
            <div className='LeaderBoardPage'>
                
                {/* Entire Leaderboard List */}
                <div className='LeaderBoard-List'>
    
                    {/* Leaderboard Header */}
                    <div className='LeaderBoardLabel'>
                           
                           {/* Header Items */}
                            <div className='EntryRank'>
                                Rank
                            </div>
                            <div className='EntryText'>
                                Player
                            </div>
                            <div className='EntryText'>
                                Time
                            </div>
                            <div className='EntryText'>
                                Status
                            </div>
                            <div className='EntryVod'>
                                Video
                            </div>
    
                    </div>
    
                    {/* Each Item/Rectangle thing is created by mapping. 'run' is the singular JSON object, index is the current entry number*/}
                    {runs.map((run, index) => (
                        
                        <div key={index} className={pattern[index % 2]}>
                            {/*^Actual rectangle container*/}
    
                            {/* vPlace of run location/style */}
                            <div className='EntryRank'>
                                {String(times[run.time])}
                            </div> 
    
                            <div className='EntryUser' style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                {run.user}
                            </div>
                            
                            {/* Time location/style */}
                            <div className='EntryText'>
                                {run.stringTime}
                            </div>
    
                            {/* Verfication Status */}
                            <div className='EntryText'>
                                {run.verified ? "Verified" : "Unverified"}
                            </div>
                            
                            {/* VOD link location/style */}
                            <div className='EntryVod'>
                                <a href={run.link} target="_blank"><button>VOD</button></a>
                            </div> 
                        </div>
                    ))}
            
                </div>
            </div>
          );
    }  
}
