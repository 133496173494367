import games from './Data/GamesCategories.json'
import { CategoryMenu } from './CategoryMenu'
import {useState} from 'react';

export function GameMenu(data){

    const [activeGame, setActiveGame] = useState('Mega Man X');
    const [key, setKey] = useState(0);
    const outData = data;

    return(
        <>
            <div className="game-buttons-parent">
                {games.map((run, index) => (
                <div>
                    <div className='game-buttons-child' key={index} onClick={() => {setKey(key+1);setActiveGame(run.game);}}>
                        {getActiveTab(run.game, activeGame)}
                    </div>
                </div>
                ))}
            </div>
            <CategoryMenu game={activeGame} data={outData} key={key}></CategoryMenu>
        </>
    )
}

function getActiveTab(game, location){
    return (location === game
            ? 
            <button style={{opacity: 1, background: `linear-gradient(-70deg, rgba(47, 185, 201, 0.3) 50%, rgba(0, 35, 96, 0.3) )`}} >
                {game}
            </button>
            : 
            <button style={{opacity: 1}} >
                {game}
            </button>
            )

  }